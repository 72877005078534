import React, { useState, useEffect, useCallback } from "react";
import { IEvent, IShow } from "@eagerdog/interfaces";
import { Constants } from "@eagerdog/constants";
import { helperService } from "src/services/helper.service";

import Input from "src/components/Input/Input";
import Dropdown, { IOption } from "src/components/Dropdown/Dropdown";
import Checkbox, { ICheck }from "src/components/Checkbox/Checkbox";

import { ShowTypes } from "./ShowTypes.definition";

import styles from "./ShowTypeTab.module.scss";

interface IProps {
  event: IEvent,
  show?: IShow,
  onChange(showType: IOption, showElements: ICheck[], armNum: number): void
}

const ShowTypeTab: React.FC<IProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [showType, setShowType] = useState<IOption>({ id: "None", value: "None" });

  const getArmBandNumStart = useCallback((_showType: string) => {
    let hasArmNum = props.event.arm_numbers_config?.filter((a: any) =>  a.show_type === _showType);

    if (hasArmNum && hasArmNum.length > 0) {
      return hasArmNum[0].arm_number_start.toString();
    }

    return "100";
  }, [props.event.arm_numbers_config]);

  const [showTypes, setShowTypes] = useState<IOption[]>([]);
  const [armNum, setArmNum] = useState<string>(getArmBandNumStart(showType.id));
  const [showElements, setShowElements] = useState<ICheck[]>([]);

  const showTypeHasElements = (showType: string) => {
    if (ShowTypes[props.event.sanctioning_club][showType][showType] !== undefined) {
      if (ShowTypes[props.event.sanctioning_club][showType][showType] !== undefined && 
          ShowTypes[props.event.sanctioning_club][showType][showType].length === 1 && 
          ShowTypes[props.event.sanctioning_club][showType][showType][0] === showType) {
        return false;
      }
    }

    return true;
  }

  const getShowTypeOptions = (sanctioningClub: string) => {
    let options:IOption[] = [];

    for (let i in ShowTypes[sanctioningClub]) {
      options.push({
        value: i,
        id: i
      });
    }

    return options;
  }

  const getShowElementOptions = useCallback((newShowType: string) => {
    let options:ICheck[] = [];

    if (props.show && props.show.show_type === newShowType) {
      for (let showElement in ShowTypes[props.event.sanctioning_club][newShowType]) {
        if (showElement !== Constants.dog_class_element.conformation_non_licensed) {
          let hasShowElement = props.show.show_elements.filter((se: any) => { return se.level ? se.level === showElement : se.show_element === showElement });

          if (hasShowElement.length > 0) {
            options.push({
              label: showElement,
              checked: true
            });
          } else {
            options.push({
              label: showElement,
              checked: false
            });
          }
        } else {
          for (let showLevel in ShowTypes[props.event.sanctioning_club][newShowType][showElement]) {
            let hasShowLevel = props.show.show_elements.filter((se: any) => { return se.level ? se.level === ShowTypes[props.event.sanctioning_club][newShowType][showElement][showLevel] : se.show_element === ShowTypes[props.event.sanctioning_club][newShowType][showElement][showLevel]; });

            if (hasShowLevel.length > 0) {
              options.push({
                label: ShowTypes[props.event.sanctioning_club][newShowType][showElement][showLevel],
                checked: true
              });
            } else {
              options.push({
                label: ShowTypes[props.event.sanctioning_club][newShowType][showElement][showLevel],
                checked: false
              });
            }
          }
        }
      }
    } else {
      for (let showElement in ShowTypes[props.event.sanctioning_club][newShowType]) {
        if (showElement !== Constants.dog_class_element.conformation_non_licensed) {
          options.push({
            label: showElement,
            checked: true
          });
        } else {
          for (let showLevel in ShowTypes[props.event.sanctioning_club][newShowType][showElement]) {
            options.push({
              label: ShowTypes[props.event.sanctioning_club][newShowType][showElement][showLevel],
              checked: true
            });
          }
        }
      }
    }

    return options;
  }, [props.event.sanctioning_club, props.show]);

  const toggleCheck = (index: number) => {
    let _options:ICheck[] = [...showElements];

    _options[index].checked = !_options[index].checked;

    setShowElements(_options);
  }

  const pShowType:any = helperService.usePrevious(showType);

  useEffect(() => {
    if (showType !== pShowType) {
      let _options:ICheck[] = getShowElementOptions(showType.id);
      setShowElements(_options);
      setArmNum(getArmBandNumStart(showType.id));
    }
  }, [showType, pShowType, getShowElementOptions, getArmBandNumStart]);

  const pShowElements:any = helperService.usePrevious(showType);
  const pArmNum:any = helperService.usePrevious(armNum);

  let { onChange } = props;

  useEffect(() => {
    if (JSON.stringify(pShowElements) !== JSON.stringify(showElements) || pArmNum !== armNum) {
      if (showElements.length > 0) {
        onChange(showType, showElements, parseInt(armNum));
      }
    }
  }, [onChange, showType, pShowElements, showElements, armNum, pArmNum]);

  useEffect(() => {
    if (!loaded) {
      const hydrateShowTypeTab = () => {
        if (props.show) {
          setShowType({ value: props.show.show_type, id: props.show.show_type });
          setArmNum(props.show.arm_number_start?.toString() ?? getArmBandNumStart(props.show.show_type));
        }
      }

      if (props.event) {
        let _options:IOption[] = getShowTypeOptions(props.event.sanctioning_club);

        setShowTypes(_options);

        if (!props.show) {
          setShowType(_options[0]);
        } else {
          hydrateShowTypeTab();
          //setShowType({ value: props.show.show_type, id: props.show.show_type });
        }
      }

      setLoaded(true);
    }
  }, [loaded, props.event, props.show, getArmBandNumStart]);

  return (
    <div className={styles.ShowTypeTab}>
      <div className="stepTitle">Show Type and Elements</div>
      <p>What type of show are you running?</p>
      <Dropdown value={showType} onChange={(e: any, value: IOption) => {
        setShowType(value);
      }} label="Show Type" options={showTypes} placeholder="Choose a Show Type" />
      {props.event.sanctioning_club === Constants.sanctioning_club.AKC && <Input className={styles.armBandStart} required onChange={(e) => { setArmNum(e.target.value); }} type="number" label="Armband Starting Number" placeholder="Enter an armband starting number." defaultValue={armNum} />}
      <p className={styles.hint}>Armband starting numbers are based on show type. Changing the armband starting number will change the starting number for all shows of this show type.</p>
      <>{showElements.length > 0 && showTypeHasElements(showType.id) && <div>
        <p>What elements are available at your show?</p>
        {showElements.map((check:ICheck, index:number) => {
          return(<div key={"showType" + index} className="showTypeWrap">
            <Checkbox key={"elem" + index} onChange={(e) => {
              toggleCheck(index);
            }} value={check.checked} id={check.label} label={check.label} />
          </div>);
        })}
      </div>}</>
    </div>
  );
};

export default ShowTypeTab;